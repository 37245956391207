<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        permission="gestaosesmt_cadastros_fonte_geradora"
        newPath="/fonte-geradora/new"
        editPath="/fonte-geradora/"
        @onToggleMenu="onToggleMenu"
        :expander="false"
        subtitle="Fonte Geradora"
        tooltip="Registro de fonte geradora"
        nomeTelaDoManual="fonte-geradora-list"
        origemHistoricoAcao="/fonte-geradora"
    >
        <template #columns>
            <Column field="id" :sortable="true" sortField="fonteGeradora.id" header="Cód." />
            <Column field="nome" :sortable="true" header="Fonte geradora" />
            <Column field="created_at" :sortable="true" sortField="fonteGeradora.created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userCreate?.name }} </small>
                </template>
            </Column>
            <Column field="updated_at" :sortable="true" sortField="fonteGeradora.updated_at" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userUpdate?.name }} </small>
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import SesmtService from '../../services/SesmtService';
import { getCurrentCompany } from '../../services/store';

export default {
    data() {
        return {
            record: {},
            page: 1,
            total: 0,
            loading: false,
            totalPage: 0,
            perPage: 10,
            recordDialog: false,
            deleteRecordDialog: false,
            filter: []
        };
    },
    created() {
        this.service = new SesmtService('/fonte-geradora');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar fonte geradora' : 'Adicionar fonte-geradora';
        },
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },

    methods: {
        onToggleMenu(event, data) {
            this.record = data;
        }
    }
};
</script>
